import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class SiteSwitcher extends Component {
    renderSiteSwitcherLink = (link, id) => {
        const { isActive, target, text, url } = link;

        return (
            <a
                key={id}
                href={url}
                className={classNames('btn', { active: isActive })}
                aria-hidden={isActive ? true : undefined}
                role="menuitem"
                target={!target || target === '' ? undefined : target}
            >
                {text}
            </a>
        );
    };

    render() {
        const { siteSwitcher } = this.props;

        return (
            <div className="site-switcher header-hidden-desktop" role="menubar">
                <div className="site-switcher-container">{siteSwitcher.map(this.renderSiteSwitcherLink)}</div>
            </div>
        );
    }
}

SiteSwitcher.propTypes = {
    siteSwitcher: PropTypes.array,
};
