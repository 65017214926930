import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Submenu from './submenu';
import MenuLink from './menu-link';

export default class MenuGroup extends Component {
    renderHeading = () => {
        const { title, link, active, externalLink, loginLink, submenuLinks } = this.props;

        if (!link.url && link.url !== '') {
            return <span className="header-hidden-mobile">{title}</span>;
        }

        return (
            <MenuLink
                link={link}
                title={title}
                isHeadingLink
                active={active}
                externalLink={externalLink}
                loginLink={loginLink}
                classes={classNames({ 'header-hidden-mobile': submenuLinks.length > 0 })}
            />
        );
    };

    renderSubmenu = () => {
        const { activeSubmenu, id, title, text, submenuLinks, active, setActiveSubmenu } = this.props;

        if (submenuLinks.length > 0) {
            return (
                <>
                    <Submenu
                        activeSubmenu={activeSubmenu}
                        title={title}
                        text={text}
                        id={id}
                        setActiveSubmenu={setActiveSubmenu}
                        active={active}
                    />
                    <ul id={id}>
                        {this.renderOversiktLink()}
                        {submenuLinks.map(this.renderSubmenuLinks)}
                    </ul>
                </>
            );
        }

        return null;
    };

    renderOversiktLink = () => {
        const { text, title, link, active } = this.props;

        if (link.url && link.url !== '') {
            return (
                <li>
                    <a
                        href={link.url}
                        role="menuitem"
                        className={classNames('header-hidden-desktop', {
                            active,
                        })}
                    >
                        {text.oversikt}
                        <span className="sr-only">{title}</span>
                    </a>
                </li>
            );
        }

        return null;
    };

    renderSubmenuLinks = (submenuLinks) => {
        return (
            <li key={submenuLinks.id} id={submenuLinks.id}>
                <MenuLink
                    link={submenuLinks.link}
                    title={submenuLinks.title}
                    active={submenuLinks.active}
                    externalLink={submenuLinks.externalLink}
                    loginLink={submenuLinks.loginLink}
                />
            </li>
        );
    };

    render() {
        const { activeSubmenu, id } = this.props;

        return (
            <li className={classNames({ 'menu-open': activeSubmenu === id })}>
                {this.renderHeading()}
                {this.renderSubmenu()}
            </li>
        );
    }
}
MenuGroup.propTypes = {
    activeSubmenu: PropTypes.string,
    id: PropTypes.string,
    link: PropTypes.object,
    setActiveSubmenu: PropTypes.func,
    submenuLinks: PropTypes.array,
    text: PropTypes.object,
    title: PropTypes.string,
};
