import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SiteSwitcher from './site-switcher';

export default class MegaMenuHeader extends Component {
    renderSiteSwitcher = () => {
        const { activeSubmenu, siteSwitcher } = this.props;

        if (siteSwitcher && siteSwitcher.length && !activeSubmenu) {
            return <SiteSwitcher siteSwitcher={siteSwitcher} />;
        }

        return null;
    };

    renderEikaAuthenticatedSubMenu = () => {
        const { eikaAuthenticateSubMenu, activeSubmenu } = this.props;

        return (
            <nav className={classNames('navigation clearfix', { 'submenu-open': activeSubmenu })}>
                <ul>
                    <li className="text-regular-medium">
                        <a href={eikaAuthenticateSubMenu.path}>
                            {eikaAuthenticateSubMenu.text}
                            <i className="ikon-pil-hoeyre" aria-hidden />
                        </a>
                    </li>
                </ul>
            </nav>
        );
    };

    render() {
        const { eikaAuthenticateSubMenu, userIsWebAuthenticated, currentSiteIsEika } = this.props;

        return (
            <div
                className={classNames('nav-header clearfix', {
                    'eika-authenticated-mega-menu': userIsWebAuthenticated && currentSiteIsEika,
                })}
            >
                <div className="container-content">
                    {eikaAuthenticateSubMenu && userIsWebAuthenticated && currentSiteIsEika
                        ? this.renderEikaAuthenticatedSubMenu()
                        : null}
                    {this.renderSiteSwitcher()}
                </div>
            </div>
        );
    }
}
MegaMenuHeader.propTypes = {
    activeSubmenu: PropTypes.string,
    currentSiteIsEika: PropTypes.bool,
    eikaAuthenticateSubMenu: PropTypes.object,
    siteSwitcher: PropTypes.array,
    userIsWebAuthenticated: PropTypes.bool,
};
