import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class MenuLink extends Component {
    icon = () => {
        if (this.props.active) {
            return <i className="ikon-active" aria-hidden />;
        }
        if (this.props.loginLink) {
            return <i className="ikon-pin-kode-24" aria-hidden />;
        }
        if (this.props.externalLink) {
            return <i className="ikon-link-out-24" aria-hidden />;
        }

        return null;
    };

    render() {
        const { url, target, title } = this.props.link;

        return (
            <a
                href={url}
                target={target === '' ? undefined : target}
                rel="noopener noreferrer"
                title={title === '' ? undefined : title}
                role="menuitem"
                className={classNames(this.props.classes, { active: this.props.active })}
            >
                {this.props.title}
                {this.icon(this.props.active)}
                {this.props.isHeadingLink ? <i className="ikon-pil-hoeyre" aria-hidden /> : null}
            </a>
        );
    }
}
MenuLink.propTypes = {
    title: PropTypes.string,
    link: PropTypes.object,
    classes: PropTypes.string,
    active: PropTypes.bool,
    loginLink: PropTypes.bool,
    externalLink: PropTypes.bool,
};
