import React from 'react';
import { AppContainer } from 'react-hot-loader';
import loadContainers from '@eika-infrastruktur/ui-engine-render';

import MegaMenu from './components/mega-menu';

const renderApp = (namespace, App) => {
    loadContainers(namespace, ({ config }) => {
        return (
            <AppContainer>
                <App {...config} />
            </AppContainer>
        );
    });
};

if (module.hot) {
    module.hot.accept('./components/mega-menu', () => {
        // eslint-disable-next-line global-require
        const MegaMenu = require('./components/mega-menu').default;

        renderApp('mega-menu', MegaMenu);
    });
}

renderApp('mega-menu', MegaMenu);
