import React, { Component } from 'react';
import MegaMenuHeader from './mega-menu-header';
import MarketGroups from './market-groups';

export default class MegaMenu extends Component {
    constructor(props) {
        super(props);
        this.state = { activeSubmenu: undefined };
    }

    setActiveSubmenu = (id) => {
        this.setState({ activeSubmenu: id });
    };

    render() {
        const {
            bliKunde,
            currentSiteIsEika,
            eikaAuthenticateSubMenu,
            marketGroups,
            siteSwitcher,
            text,
            userIsWebAuthenticated,
        } = this.props;

        return (
            <>
                <MegaMenuHeader
                    activeSubmenu={this.state.activeSubmenu}
                    currentSiteIsEika={currentSiteIsEika}
                    eikaAuthenticateSubMenu={eikaAuthenticateSubMenu}
                    siteSwitcher={siteSwitcher}
                    userIsWebAuthenticated={userIsWebAuthenticated}
                />
                <MarketGroups
                    activeSubmenu={this.state.activeSubmenu}
                    bliKunde={bliKunde}
                    currentSiteIsEika={currentSiteIsEika}
                    eikaAuthenticateSubMenu={eikaAuthenticateSubMenu}
                    marketGroups={marketGroups}
                    setActiveSubmenu={this.setActiveSubmenu}
                    siteSwitcher={siteSwitcher}
                    text={text}
                    userIsWebAuthenticated={userIsWebAuthenticated}
                />
            </>
        );
    }
}
