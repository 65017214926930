import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Submenu extends Component {
    openMenu = () => {
        this.props.setActiveSubmenu(this.props.id);
    };

    closeMenu = () => {
        this.props.setActiveSubmenu(undefined);
    };

    render() {
        const { activeSubmenu, title, text, id, active } = this.props;

        return (
            <>
                <button
                    type="button"
                    className={classNames('sub-menu-btn header-hidden-desktop', {
                        active,
                    })}
                    aria-label={title}
                    aria-haspopup="true"
                    aria-controls={id}
                    onClick={this.openMenu}
                    aria-expanded={activeSubmenu === id ? true : undefined}
                >
                    {title}
                </button>
                <button
                    type="button"
                    className="mobile-menu-back"
                    aria-label={text.tilbakeTilHovedmeny}
                    onClick={this.closeMenu}
                >
                    {title}
                </button>
            </>
        );
    }
}
Submenu.propTypes = {
    activeSubmenu: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.object,
    setActiveSubmenu: PropTypes.func,
    active: PropTypes.bool,
};
