import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MenuGroup from './menu-group';

export default class MarketGroup extends Component {
    renderMarketGroups = (marketGroup, i) => {
        const { siteSwitcher, activeSubmenu, marketGroups } = this.props;

        if (marketGroup.length <= 0) {
            return null;
        }

        return (
            <div
                key={`market-group-${i}`}
                className={classNames('nav-mega-menu', {
                    'submenu-open': activeSubmenu,
                    'site-switcher-visible': siteSwitcher && siteSwitcher.length && !activeSubmenu,
                })}
            >
                <ul role="menu" className="container-content">
                    {marketGroup.map(this.renderMenuGroup)}
                    {i === marketGroups.length - 1 ? this.renderBliKunde() : null}
                </ul>
            </div>
        );
    };

    renderMenuGroup = (menuGroup) => {
        const { activeSubmenu, setActiveSubmenu, text } = this.props;

        return (
            <MenuGroup
                key={menuGroup.id}
                id={menuGroup.id}
                link={menuGroup.link}
                title={menuGroup.title}
                submenuLinks={menuGroup.submenuLinks}
                setActiveSubmenu={setActiveSubmenu}
                activeSubmenu={activeSubmenu}
                active={menuGroup.active}
                externalLink={menuGroup.externalLink}
                loginLink={menuGroup.loginLink}
                text={text}
            />
        );
    };

    renderBliKunde = () => {
        const { bliKunde, text } = this.props;

        if (bliKunde && bliKunde.url !== '') {
            return (
                <li>
                    <a
                        href={bliKunde.url}
                        role="menuitem"
                        className={classNames('header-hidden-desktop', {
                            active: bliKunde.active,
                        })}
                    >
                        {text.bliKunde}
                    </a>
                </li>
            );
        }

        return null;
    };

    render() {
        return <>{this.props.marketGroups.map(this.renderMarketGroups)}</>;
    }
}
MarketGroup.propTypes = {
    activeSubmenu: PropTypes.string,
    bliKunde: PropTypes.object,
    marketGroups: PropTypes.array,
    setActiveSubmenu: PropTypes.func,
    siteSwitcher: PropTypes.array,
    text: PropTypes.object,
};
